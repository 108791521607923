import React from 'react';
import { List, Section } from '../../../../components';

const IconNotes = () => {
  return (
    <Section title="Notes">
      <List type="unordered">
        <li>
          Icons within buttons are automatically styled and scaled. For example,{' '}
          <code>large</code> buttons will scale icons to <code>medium</code>.
        </li>
        <li>Filetype icons are set to large size by default.</li>
        <li>
          UI Control icons are set to small size by default. Certain UI Control
          icons may have different sizing which is indicated in the icon name.
          These icons cannot be scaled to sizes not listed in their name.
        </li>
        <li>
          The fill color of all icons are provided by the CSS styling. The
          exception to this are the Filetype icons which have custom fill
          colors.
        </li>
        <li>
          The Battery icons, <code>IconBattery</code> and{' '}
          <code>IconBatteryLow</code>, have been renamed to{' '}
          <code>IconBattery100</code> and <code>IconBattery25</code>,
          respectively. The original components still exist, however, as
          duplicates of the new components to avoid introducing a breaking
          change.
        </li>
      </List>
    </Section>
  );
};

export default IconNotes;
