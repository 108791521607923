import React from 'react';
import Loadable from 'react-loadable';
import {
  List,
  PropListItem,
  PropList,
  Section,
  SpacePartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const IconPropList = () => {
  return (
    <Section title="Props">
      <PropList header="Visual">
        <PropListItem name="size" types={['string']}>
          <Text>Determines the size of the icon.</Text>
          <List type="unordered">
            <li>
              <code>small</code>
            </li>
            <li>
              <code>medium</code> (default)
            </li>
            <li>
              <code>large</code>
            </li>
          </List>
        </PropListItem>

        <PropListItem name="color" types={['string']}>
          <Text>Determines the color of the icon.</Text>
          <List type="unordered">
            <li>
              <code>contrast</code>
            </li>
            <li>
              <code>default</code> (default)
            </li>
            <li>
              <code>subtle</code>
            </li>
            <li>
              <code>nonessential</code>
            </li>
          </List>
        </PropListItem>

        <SpacePartial />
      </PropList>

      <PropList header="Functional">
        <PropListItem name="title" types={['string']}>
          <Text>
            Used in aiding screen readers describing the icon. Defaults to the
            name of the icon.
          </Text>
        </PropListItem>
      </PropList>
    </Section>
  );
};

export default IconPropList;
