import React from 'react';
import Loadable from 'react-loadable';
import {
  ClassnamePartial,
  EnvironmentPartial,
  List,
  PropListItem,
  PropList,
  QaIdPartial,
  Section,
} from '../../../../components';
const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const IconPropList = () => {
  return (
    <Section title="Props">
      <PropList header="Visual">
        <ClassnamePartial componentName="icon" />

        <PropListItem name="color" types={['string']}>
          <Text>Determines the color of the icon.</Text>
          <List type="unordered">
            <li>
              <code>contrast</code>
            </li>
            <li>
              <code>default</code> (default)
            </li>
            <li>
              <code>subtle</code>
            </li>
            <li>
              <code>nonessential</code>
            </li>
            <li>
              <code>black</code>
            </li>
            <li>
              <code>white</code>
            </li>
            <li>
              <code>action</code>
            </li>
            <li>
              <code>information</code>
            </li>
            <li>
              <code>confirmation</code>
            </li>
            <li>
              <code>warning</code>
            </li>
            <li>
              <code>critical</code>
            </li>
          </List>
        </PropListItem>

        <EnvironmentPartial />

        <PropListItem name="size" types={['string']}>
          <Text>Determines the size of the icon.</Text>
          <List type="unordered">
            <li>
              <code>small</code>
            </li>
            <li>
              <code>medium</code> (default)
            </li>
            <li>
              <code>large</code>
            </li>
          </List>
        </PropListItem>
      </PropList>

      <PropList header="Functional">
        <PropListItem name="title" types={['string']}>
          <Text>
            Sets the <code>title</code> element on the icon’s SVG. By default,
            the name of the icon is the title. If the name of the icon doesn’t
            match its meaning in your interface, be sure to pass a title to aid
            those using screen readers.
          </Text>
        </PropListItem>
      </PropList>

      <PropList header="Miscellaneous">
        <QaIdPartial componentName="icon" />
      </PropList>
    </Section>
  );
};

export default IconPropList;
