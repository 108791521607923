import React from 'react';
import { List, Section } from '../../../../components';

const IconNotes = () => {
  return (
    <Section title="Notes">
      <List type="unordered">
        <li>Icons within buttons are automatically styled.</li>
        <li>Filetype icons are set to large size by default.</li>
        <li>
          UI Control icons are set to small size by default. Certain UI Control
          icons may have different sizing which is indicated in the icon name.
        </li>
      </List>
    </Section>
  );
};

export default IconNotes;
